import React from "react";
import "./Button.css";
import { useTelegram } from "../../hooks/useTelegram";

const Button = (props) => {
  const {
    tg: { HapticFeedback },
  } = useTelegram();

  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }

    HapticFeedback.impactOccurred("medium");
  };

  return (
    <button
      {...props}
      onClick={handleClick}
      className={"button " + props.className}
    />
  );
};

export default Button;
