import "./App.css";
import { useTelegram } from "./hooks/useTelegram";
import Button from "./components/Button/Button";

function App() {
  const {
    tg,
    user,
    toggleButtonVisibility,
    toggleButtonProgress,
    expand,
    toggleBackButtonVisibility,
  } = useTelegram();

  return (
    <div>
      <h3>User: {user?.username}</h3>
      <Button onClick={toggleButtonVisibility}>toggle Button</Button>
      <Button onClick={toggleButtonProgress}>progress</Button>
      <Button onClick={toggleBackButtonVisibility}>back</Button>
      <Button onClick={expand}>expand</Button>
      <Button
        onClick={() =>
          tg.showPopup({
            title: "title",
            message: "message",
            buttons: [
              { type: "ok", text: "Ok" },
              { type: "destructive", text: "Fuck off" },
            ],
          })
        }
      >
        popup
      </Button>
      <Button
        onClick={() => {
          tg.sendData(JSON.stringify({ name: "John", age: 18 }));
        }}
      >
        Send Data
      </Button>
    </div>
  );
}

export default App;
